import api from "@/axios";
import axios from 'axios'


export default class ClienteService {



    getssclifinancs(id) {
        const params = { id }
        return api.get('api/ssclifinancs', {params}).then((res) => res.data);
    }

    getsscliprodaberto(id) {
        const params = { id }
        return api.get('api/sscliprodaberto', {params}).then((res) => res.data);
    }

    getClienteNovo() {
        return api.get('api/ssclientenovoget' ).then((res) => res.data);
    }


    confirmarCli(cliente){
        return api.post('api/ssclienteconf', {cliente}).then((res) => res.data);
    }

    getssclienteindex(params) {
        return api.get('api/ssclienteindex', {params} ).then((res) => res.data);
    }


    sspeslocimg(id) {
        const params = { id }
        return api.get('api/sspeslocimg', { params } ).then((res) => res.data);
    }


    ssdownloadfile(id) {
        const params = { id }
        return api.get('api/ssdownloadfile', { params }).then((res) => res.data);
    }

    ssdeletefile(id) {
        const params = { id }
        return api.get('api/ssdeletfile', { params }).then((res) => res.data);
    }

    getsscliente(id) {
        const params = { id }
        return api.get('api/ssclienteget', {params}).then((res) => res.data);
    }

    savesscliente(cliente) {
        return api.post('api/ssclientesave', {cliente} ).then((res) => res.data);
    }

    getClientes() {
        return fetch('demo/data/customers-small.json').then(res => res.json()).then(d => d.data);
        //return api.get('testarapi' ).then((res) => res.data);
    }

    getPesqCidade(params){
        // console.log(baseURLPython)
        return api.get('api/sspesqcid', {params}).then((res) => res.data);
    }

    getsscliocors(id) {
        const params = { id }
        return api.get('api/sscliocors', {params}).then((res) => res.data);
    }

    getsscliocor(id) {
        const params = { id }
        return api.get('api/sscliocor', {params}).then((res) => res.data);
    }

    savessocor(ocor) {
        return api.post('api/sscliocorsave', {ocor} ).then((res) => res.data);
    }

    ssdeleteocor(id) {
        const params = { id }
        return api.get('api/sscliocordel', { params }).then((res) => res.data);
    }


    getssclirefers(id) {
        const params = { id }
        return api.get('api/ssclirefers', {params}).then((res) => res.data);
    }

    getssclirefer(id) {
        const params = { id }
        return api.get('api/ssclirefer', {params}).then((res) => res.data);
    }

    savessrefer(refer) {
        return api.post('api/ssclirefersave', {refer} ).then((res) => res.data);
    }

    ssdeleterefer(id) {
        const params = { id }
        return api.get('api/ssclireferdel', { params }).then((res) => res.data);
    }


    getPesquisaCEP(cep) {
        return axios.get('https://viacep.com.br/ws/' + cep + '/json/', ).then((res) => res.data);
    }


    getProductsSmall() {
        return fetch('demo/data/products-small.json').then(res => res.json()).then(d => d.data);
    }

    getProductsMixed() {
        return fetch('demo/data/products-mixed.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
        return fetch('demo/data/products-orders-small.json').then(res => res.json()).then(d => d.data);
    }


    getLeads() {
        return fetch('demo/data/leads.json').then(res => res.json()).then(d => d.data);
    }

    getOrcamentos() {
        return fetch('demo/data/orcamentos.json').then(res => res.json()).then(d => d.data);
    }

    getPedidos() {
        return fetch('demo/data/pedidos.json').then(res => res.json()).then(d => d.data);
    }

    getObras() {
        return fetch('demo/data/obras.json').then(res => res.json()).then(d => d.data);
    }

    getProjetos() {
        return fetch('demo/data/projetos.json').then(res => res.json()).then(d => d.data);
    }


}
