<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="clienteData && loadingData === false">
      <h6 v-if="clienteData.id">Editando Cliente {{ clienteData.codexterno }} <span class="text-400">({{ clienteData.id }})</span>
      <span :class="'ml-3  cliente-badge status-' + (clienteData.status ? clienteData.status.toLowerCase() : '')">{{ nomeStatus(clienteData.status) }}</span>
      </h6>
      <h6 v-else>Novo Cliente</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="saveCliente" :loading="loadingBtnSave" v-if="clienteData && clienteData.status === 'EL'"/>
          <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" v-if="userData && userData.tipo === 'admin'"/>
          <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="exportCSV($event)"  v-if="userData && userData.tipo === 'admin'"/>
          <Button label="Confirmar Cliente" icon="pi pi-check" class="mr-2 p-button-outlined" @click="confCliente()" v-if="clienteData && clienteData.id > 0 && clienteData.status === 'EL'" />
          <Button label="Nova Ocorrência" icon="pi pi-plus" class="mr-2 p-button-outlined" @click="novaOcor()" v-if="clienteData && clienteData.id > 0"/>
          <Button label="Nova Referência" icon="pi pi-plus" class="mr-2 p-button-outlined" @click="novaRefer()"  v-if="clienteData && clienteData.id > 0 && clienteData.status === 'EL'"/>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12" >

      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" :activeIndex="activeIndex" @tab-change='alterouTab'>

        <TabPanel>

          <template #header>
            <span>Dados Principais</span>
            <i class="pi pi-user ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-3 md:col-3">
              <label for="nome">Nome</label>
              <InputText id="nome"
                         type="text"
                         :class="(validCliente || validConfirmar) && !clienteData.nome ? 'p-invalid' : ''"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.nome"/>
              <small class="p-invalid" v-if="(validCliente || validConfirmar) && !clienteData.nome">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="fantasia">Fantasia</label>
              <InputText id="fantasia"
                         type="text"
                         :class="validCliente && !clienteData.fantasia ? 'p-invalid' : ''"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.fantasia"/>
              <small class="p-invalid" v-if="validCliente && !clienteData.fantasia">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3" v-if="clienteData.tipo === 'F'">
              <label for="cnpj">CPF</label>
              <InputMask id="cnpj"
                         mask="999.999.999-99"
                         :class="(validCliente || validConfirmar) && !clienteData.cnpj ? 'p-invalid' : ''"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.cnpj" />
              <small class="p-invalid" v-if="(validCliente || validConfirmar) && !clienteData.cnpj">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3" v-if="clienteData.tipo === 'J'">
              <label for="cnpj">CNPJ</label>
              <InputMask id="cnpj"
                         mask="99.999.999/9999-99"
                         :class="(validCliente || validConfirmar) && !clienteData.cnpj ? 'p-invalid' : ''"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.cnpj" />
              <small class="p-invalid" v-if="(validCliente || validConfirmar) && !clienteData.cnpj">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="inscricao">Inscrição</label>
              <InputText id="inscricao"
                         type="text"
                         :class="(validCliente || validConfirmar) && !clienteData.inscricao ? 'p-invalid' : ''"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.inscricao"/>
              <small class="p-invalid" v-if="(validCliente || validConfirmar) && !clienteData.inscricao">Campo obrigatório.</small>
            </div>


            <div class="field col-3 md:col-3">
              <label for="nascim">Nascimento</label>
              <Calendar :showIcon="true"
                        :showButtonBar="true"
                        v-model="clienteData.nascim"
                        :disabled="clienteData && clienteData.status !== 'EL'"
                        dateFormat="dd/mm/yy" />
            </div>

            <div class="field col-3 md:col-3">
              <label for="tipo">Tipo Pessoa</label>
              <Dropdown id="tipo" v-model="clienteData.tipo"
                        :options="tipoItems" optionLabel="name"
                        optionValue="code"
                        :disabled="clienteData && clienteData.status !== 'EL'"
                        placeholder="Selecione o tipo de pessoa"></Dropdown>
            </div>

            <div class="field col-3 md:col-3">
              <label for="tel1">Telefone</label>
              <div class="p-inputgroup">
                <InputText
                    id="tel1"
                    type="number"
                    :disabled="clienteData && clienteData.status !== 'EL'"
                    v-model="clienteData.tel1"
                    :class="validConfirmar && !clienteData.tel1 ? 'p-invalid' : ''"/>
                <Button icon="pi pi-whatsapp" class="p-button" @click="enviarZap()"/>
              </div>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.tel1">Campo obrigatório.</small>
            </div>


            <div class="field col-3 md:col-3">
              <label for="email">E-mail</label>
              <div class="p-inputgroup">
                <InputText
                    id="email"
                    type="text"
                    :disabled="clienteData && clienteData.status !== 'EL'"
                    v-model="clienteData.email"
                    :class="validConfirmar && !clienteData.email ? 'p-invalid' : ''"/>
                <Button icon="pi pi-send" class="p-button" @click="enviarEmail()"/>
              </div>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.email">Campo obrigatório.</small>
            </div>



            <div class="col-12" >
              <h5>Endereço</h5>
            </div>

          <div class="field col-3 md:col-3">
            <label for="cep">CEP</label>
            <div class="p-inputgroup">
              <InputMask
                  id="tel1"
                  mask="99.999-999"
                  :disabled="clienteData && clienteData.status !== 'EL'"
                  v-model="clienteData.cep"
                  :class="validConfirmar && !clienteData.cep ? 'p-invalid' : ''"/>
              <Button label="Pesquisar CEP" :loading="loadingCEP" @click="pesquisarCEP()"/>
            </div>
            <small class="p-invalid" v-if="validConfirmar && !clienteData.cep">Campo obrigatório.</small>
          </div>

            <div class="field col-3 md:col-3">
              <label for="logradouro">Logradouro</label>
              <InputText
                  id="logradouro"
                  type="text"
                  :disabled="clienteData && clienteData.status !== 'EL'"
                  v-model="clienteData.rua"
                  :class="validConfirmar && !clienteData.rua ? 'p-invalid' : ''"/>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.rua">Campo obrigatório.</small>
            </div>
            <div class="field col-3 md:col-3">
              <label for="numero">Número</label>
              <InputText
                  id="numero"
                  type="text"
                  :disabled="clienteData && clienteData.status !== 'EL'"
                  v-model="clienteData.numero"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="bairro">Bairro</label>
              <InputText
                  id="bairro"
                  type="text"
                  :disabled="clienteData && clienteData.status !== 'EL'"
                  v-model="clienteData.bairro"
                  :class="validConfirmar && !clienteData.bairro ? 'p-invalid' : ''"/>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.bairro">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="cidade">Cidade</label>
              <AutoComplete placeholder="Pesquisar cidade"
                            :dropdown="true"
                            :multiple="false"
                            :disabled="clienteData && clienteData.status !== 'EL'"
                            v-model="selectedAutoValueCidade"
                            :suggestions="autoFilteredCidade"
                            @complete="searchCidade($event)"
                            field="descricao"
                            @item-select="selecionaCidade($event)"
                            :class="validConfirmar && !clienteData.cidade ? 'p-invalid' : ''">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 60%">{{slotProps.item.descricao}}</div>
                    <div style="width: 10%"  class="text-right">{{slotProps.item.estado}}</div>
                  </div>
                </template>
              </AutoComplete>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.cidade">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="compl">Complemento</label>
              <InputText id="compl" type="text"
                         :disabled="clienteData && clienteData.status !== 'EL'"
                         v-model="clienteData.compl"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="estado">Estado</label>
              <Dropdown
                  id="estado"
                  :disabled="clienteData && clienteData.status !== 'EL'"
                  v-model="clienteData.estado"
                  :options="estadoItems"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Selecionar estado"
                  :class="validConfirmar && !clienteData.estado ? 'p-invalid' : ''"/>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.estado">Campo obrigatório.</small>
            </div>




            <div class="col-12" >
              <h5>Faturamento</h5>
            </div>

            <div class="field col-3 md:col-3">

              <label for="repres">Representante</label>
              <div class="p-inputgroup">
                <InputText
                    id="repres"
                    type="text"
                    v-model="clienteData.repres"

                    :disabled="(clienteData && clienteData.status !== 'EL') || (userData && userData.tipo !== 'admin')"



                    :class="validConfirmar && !clienteData.repres ? 'p-invalid' : ''"/>
              </div>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.repres">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="limite">Limite</label>
              <div class="p-inputgroup">
                <InputNumber id="limite"
                             v-model="clienteData.limite"
                             mode="decimal"
                             locale="pt-BR"
                             :min="0"
                             :minFractionDigits="2"
                             :disabled="clienteData && clienteData.status !== 'EL'"
                             :class="validConfirmar && !clienteData.limite ? 'p-invalid' : ''"/>
              </div>
              <small class="p-invalid" v-if="validConfirmar && !clienteData.limite">Campo obrigatório.</small>
            </div>


            <div class="field col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4"
                        :disabled="clienteData && clienteData.status !== 'EL'"
                        v-model="clienteData.observacao"/>
            </div>

          </div>
        </TabPanel>

        <TabPanel >
          <template #header>
            <span>Financeiro</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>

            <div class="col-12" >
                <h6 >Total em aberto: R$  {{ formatDecimal(totalAberto) }} <span class="text-400 ml-5">Total vencido: </span>
                    <span :class="'ml-3  cliente-badge status-i'"> R$ {{ formatDecimal(totalVencido) }}</span>
                </h6>

            </div>

          <DataTable v-if="financeirosData" :value="financeirosData" :scrollable="true" scrollHeight="400px" :loading="loadingFinanceiro" scrollDirection="both" class="mt-3">
            <Column header="Código" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.codexterno}}
              </template>
            </Column>


              <Column header="Nota" :style="{width:'200px'}">
                  <template #body="slotProps">
                      {{slotProps.data.nota}}
                  </template>
              </Column>

            <Column header="Pedido" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.pedido}}
              </template>
            </Column>

            <Column header="Número" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.numero}}
              </template>
            </Column>

            <Column header="Emissão" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.emissao)}}
              </template>
            </Column>

            <Column header="Vencimento" :style="{width:'200px'}" >
              <template #body="slotProps">


                  <span :class="slotProps.data.stsvenc === 'S' ? 'financeiro-badge status-despesa' : ''">{{formatDate(slotProps.data.vencimento)}}</span>

              </template>
            </Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'financeiro-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{ nomeStatusFinan(data.status) }}</span>
              </template>
            </Column>

            <Column field="valor" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.valor)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Produtos em Aberto</span>
            <i class="pi pi-folder-open ml-2"></i>
          </template>

            <DataTable v-if="prodAbertoData" :value="prodAbertoData" :scrollable="true" scrollHeight="400px" :loading="loadingProdAberto" scrollDirection="both" class="mt-3">

                <Column header="Código" :style="{width:'200px'}">
                    <template #body="slotProps">
                        {{slotProps.data.codexterno}}
                    </template>
                </Column>

                <Column header="Nota" :style="{width:'500px'}">
                    <template #body="slotProps">
                        {{slotProps.data.desc}}
                    </template>
                </Column>

                <Column header="Emissão" :style="{width:'200px'}">
                    <template #body="slotProps">
                        {{formatDate(slotProps.data.emiss)}}
                    </template>
                </Column>

                <Column header="Prod." :style="{width:'200px'}">
                    <template #body="slotProps">
                        {{slotProps.data.prod}}
                    </template>
                </Column>

                <Column field="valor" header="Pendente" :style="{width:'150px'}" >
                    <template #body="{data}">
                        <span class="text-bold">{{formatDecimal(data.pendente)}}</span>
                    </template>
                </Column>

                <Column field="qtdent" header="Qtd.Ent." :style="{width:'150px'}" >
                    <template #body="{data}">
                        <span class="text-bold">{{formatDecimal(data.qtdent)}}</span>
                    </template>
                </Column>


                <Column field="qtdven" header="Qtd.Ven." :style="{width:'150px'}" >
                    <template #body="{data}">
                        <span class="text-bold">{{formatDecimal(data.qtdven)}}</span>
                    </template>
                </Column>

            </DataTable>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Ocorrências</span>
            <i class="pi pi-exclamation-circle ml-2"></i>
          </template>

          <DataTable v-if="ocorsData" :value="ocorsData" :scrollable="true" scrollHeight="400px" :loading="loadingOcors" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="abrirOcor(slotProps.data.id)" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at)}}
              </template>
            </Column>
<!--            <Column field="tipo" header="Tipo" :style="{width:'200px'}"></Column>-->

            <Column field="tipo" header="Tipo" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge tipo-' + (data.tipo ? data.tipo.toLowerCase() : '')">{{data.tipo}}</span>
              </template>
            </Column>

            <Column field="obs" header="Observação" :style="{width:'300px'}" ></Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge status-' + (data.status ? data.status.toLowerCase() : '')"> {{ data.status }} </span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>


          </DataTable>


        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Referências</span>
            <i class="pi pi-users ml-2"></i>
          </template>

          <DataTable v-if="refersData" :value="refersData" :scrollable="true" scrollHeight="400px" :loading="loadingRefers" scrollDirection="both" class="mt-3">

            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="abrirRefer(slotProps.data.id)" />
              </template>
            </Column>

            <Column field="id" header="Código" :style="{width:'70px'}" ></Column>

            <Column header="Data" :style="{width:'110px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at)}}
              </template>
            </Column>


            <Column field="tipo" header="Tipo" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge tipo-' + (data.tipo ? data.tipo.toLowerCase() : '')">{{nomeTipo(data.tipo)}}</span>
              </template>
            </Column>

            <Column field="nome" header="Nome" :style="{width:'300px'}" ></Column>
            <Column field="tel1" header="Telefone" :style="{width:'150px'}" ></Column>
            <Column field="contato" header="Contato" :style="{width:'150px'}" ></Column>
            <Column field="obs" header="Observação" :style="{width:'300px'}" ></Column>



          </DataTable>


        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Leads</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>
            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>

          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Orçamentos</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>

          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Vendas</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <DataTable v-if="pedidosData" :value="pedidosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/pedidodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>



      </TabView>
    </div>


    <Sidebar v-model:visible="sidebarOcorrencia" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5 v-if="!ocorrenciaData.id">Nova Ocorrência</h5>
      <h5 v-if="ocorrenciaData.id">Editando Ocorrência {{ ocorrenciaData.id }}</h5>


      <div class="grid p-fluid mt-3" >
        <div class="col-12 md:col-12">
          <label for="tipoocor">Tipo</label>
          <div class="p-inputgroup">

            <Dropdown id="tipoocor"
                      v-model="ocorrenciaData.tipo"
                      :options="tipoocorItems"
                      optionLabel="name"
                      optionValue="code"
                      :class="validOcor && !ocorrenciaData.tipo ? 'p-invalid' : ''"
                      placeholder="Selecione o tipo">
            </Dropdown>
            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.tipo">Campo obrigatório.</small>

          </div>
        </div>

        <div class="col-12 md:col-12" >
          <label for="obsocor">Observações</label>
          <div class="p-inputgroup">

            <Textarea id="obsocor"
                      rows="4"
                      :class="validOcor && !ocorrenciaData.obs ? 'p-invalid' : ''"
                      v-model="ocorrenciaData.obs" />

            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.obs">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-6 md:col-6" v-if="ocorrenciaData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirOcor()"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Fechar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarOcorrencia = false; validOcor = false;"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Salvar" icon="pi pi-save" class="mr-2 p-button-outlined" @click="saveOcorrencia()" :loading="loadingBtnSaveOcor" />
        </div>




      </div>
    </Sidebar>



    <Sidebar v-model:visible="sidebarReferencia" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5 v-if="!referenciaData.id">Nova Referência</h5>
      <h5 v-if="referenciaData.id">Editando Referência {{ referenciaData.id }}</h5>


      <div class="grid p-fluid mt-3" >
        <div class="col-12 md:col-12">
          <label for="tipoocor">Tipo</label>
          <div class="p-inputgroup">

            <Dropdown id="tiporefer"
                      v-model="referenciaData.tipo"
                      :options="tiporeferItems"
                      optionLabel="name"
                      optionValue="code"
                      :class="validRefer && !referenciaData.tipo ? 'p-invalid' : ''"
                      placeholder="Selecione o tipo">
            </Dropdown>
            <small class="p-invalid" v-if="validRefer && !referenciaData.tipo">Campo obrigatório.</small>

          </div>
        </div>

        <div class="col-12 md:col-12" >
          <label for="nomerefer">Nome</label>
          <div class="p-inputgroup">
            <InputText id="nomerefer"
                       type="text"
                       :class="validRefer && !referenciaData.nome ? 'p-invalid' : ''"
                       v-model="referenciaData.nome"/>

            <small class="p-invalid" v-if="validRefer && !referenciaData.obs">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-6 md:col-6" >
          <label for="tel1refer">Telefone</label>
          <div class="p-inputgroup">
            <InputText id="tel1refer"
                       type="text"
                       :class="validRefer && !referenciaData.tel1 ? 'p-invalid' : ''"
                       v-model="referenciaData.tel1"/>

            <small class="p-invalid" v-if="validRefer && !referenciaData.tel1">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-6 md:col-6" >
          <label for="contatorefer">Contato</label>
          <div class="p-inputgroup">
            <InputText id="contatorefer"
                       type="text"
                       :class="validRefer && !referenciaData.contato ? 'p-invalid' : ''"
                       v-model="referenciaData.contato"/>

            <small class="p-invalid" v-if="validRefer && !referenciaData.contato">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-12 md:col-12" >
          <label for="obsrefer">Observação</label>
          <div class="p-inputgroup">

            <Textarea id="obsrefer"
                      rows="4"
                      v-model="referenciaData.obs" />

          </div>
        </div>
        <div class="col-6 md:col-6" >
          <Button label="Fechar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarReferencia = false; validRefer = false;"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Salvar" icon="pi pi-save" class="mr-2 p-button-outlined" @click="saveReferencia()" :loading="loadingBtnSaveRefer" />
        </div>


        <div class="col-6 md:col-6" v-if="referenciaData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirRefer()"  />
        </div>




      </div>
    </Sidebar>


    <ConfirmDialog group="dialog" />



  </div>
</template>
<script>
import ClienteService from "@/service/ClienteService";
import api from "@/axios";
import moment from 'moment'

export default {
  components: {

  },
  data() {
    return {
        prodAbertoData: [],
        loadingProdAberto: true,

      vlrTotalFinancAberto: 0,
      vlrTotalFinancVencido: 0,

      validConfirmar: false,
      userData: null,
      financeirosData: [],
        totalAberto: 0,
        totalVencido: 0,
      validCliente: false,

      activeIndex: 0,
      loadingBtnSaveOcor: false,
      validOcor: false,
      sidebarOcorrencia: false,
      ocorrenciaData: {},

      referenciaData: {},
      sidebarReferencia: false,
      validRefer: false,
      loadingBtnSaveRefer: false,
      refersData: [],
      loadingRefers: false,

      arqSubindo: '',
      arqFaixada: null,
      arqTelhado: null,
      arqDisjuntorGeral: null,
      arqQuadroDistribuicao: null,
      arqRelogio: null,
      arqContaEnergia: null,
      arquivos: [],
      tipoUpload: '',
      baseUrl: '',

      tipoocorItems: [
        {code: 'AV', name: 'Aviso'},
        {code: 'CO', name: 'Confirmação'},
        {code: 'PR', name: 'Problema'},
      ],

      tiporeferItems: [
        {code: '1', name: 'Instituição'},
        {code: '2', name: 'Empresa'},
        {code: '3', name: 'Banco'},
      ],

      telhorieItems: [
        {code: 'NO', name: 'Norte'},
        {code: 'SU', name: 'Sul'},
        {code: 'LE', name: 'Leste'},
        {code: 'OE', name: 'Oeste'},
        {code: 'ND', name: 'Nordeste'},
        {code: 'SD', name: 'Sudoeste'},
        {code: 'NR', name: 'Noroeste'},

      ],
      geradorItems: [
        {code: 'SIM', name: 'Sim'},
        {code: 'NAO', name: 'Não'},

      ],
      telhestrItems: [
        {code: 'MAD', name: 'Madeira'},
        {code: 'MET', name: 'Metal'},
        {code: 'TER', name: 'Terça'},
        {code: 'OUT', name: 'Outro'},
      ],
      telhadoItems: [
        {code: 'FIB', name: 'Fibrocimento'},
        {code: 'COL', name: 'Colonial'},
        {code: 'ZIN', name: 'Zinco'},
        {code: 'OUT', name: 'Outro'},
      ],
      tensaoItems: [
        {code: '127', name: '127V'},
        {code: '220', name: '220V'},
        {code: '380', name: '380V'},
      ],
      sistemaItems: [
        {code: 'MN', name: 'Monofásico'},
        {code: 'BI', name: 'Bifásico'},
        {code: 'TR', name: 'Trifásico'},
      ],
      radioValueEspaco: null,
      radioValueAumento: null,
      radioValueInternet: null,
      radioValueGrupo: null,
      clientecadData: {
        'perfil': 'coisa'
      },
      selectedAutoValueCidade: null,
      autoFilteredCidade: [],
      autoValueCidade: [],
      loadingCEP: false,
      loadingData: false,
      loadingBtnSave: false,
      loadingBtn: [false, false, false],
      pedidosData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingOcors: null,
      ocorsData: null,

      loadingFinanceiro: true,
      loadingLeads: true,
      leadService: null,
      leadsData: null,
      clienteData: {
        'id': 0,
        'tipo': 'J',
        'status': 'EL'
      },

      tipoItems: [
        {code: 'F', name: 'Pessoa Física'},
        {code: 'J', name: 'Pessoa Jurídica'},
      ],

      estadoItems: [
        {code: 'AC', name: 'Acre'},
        {code: 'AL', name: 'Alagoas'},
        {code: 'AP', name: 'Amapá'},
        {code: 'AM', name: 'Amazonas'},
        {code: 'BA', name: 'Bahia'},
        {code: 'CE', name: 'Ceará'},
        {code: 'DF', name: 'Distrito Federal'},
        {code: 'ES', name: 'Espírito Santo'},
        {code: 'GO', name: 'Goiás'},
        {code: 'MA', name: 'Maranhão'},
        {code: 'MT', name: 'Mato Grosso'},
        {code: 'MS', name: 'Mato Grosso do Sul'},
        {code: 'MG', name: 'Minas Gerais'},
        {code: 'PA', name: 'Pará'},
        {code: 'PB', name: 'Paraíba'},
        {code: 'PR', name: 'Paraná'},
        {code: 'PE', name: 'Pernambuco'},
        {code: 'PI', name: 'Piauí'},
        {code: 'RJ', name: 'Rio de Janeiro'},
        {code: 'RN', name: 'Rio Grande do Norte'},
        {code: 'RS', name: 'Rio Grande do Sul'},
        {code: 'RO', name: 'Rondônia'},
        {code: 'RR', name: 'Roraima'},
        {code: 'SC', name: 'Santa Catarina'},
        {code: 'SP', name: 'São Paulo'},
        {code: 'SE', name: 'Sergipe'},
        {code: 'TO', name: 'Tocantins'},
      ],
      dropdownItem: null,
    }
  },

  created() {
    this.clienteService = new ClienteService();

  },


  mounted() {
    console.log('mounted clidetalhe')
    // console.log(api.getUri())
    // this.baseUrl = api.getUri() + 'api/ssuploadfile'
    let idcliente = this.$route.params.id

    if (idcliente > 0){
      this.loadingData = true
      this.clienteService.getsscliente(idcliente).then((data) => {
        console.log('getCliente')
        console.log(data)
        this.userData = data[0]
        this.clienteData = data[1]
        //this.financeirosData = data[2]
        this.ocorrenciaData.pessoa_id = this.clienteData.id


        // this.financeirosData.forEach(financ => {
        //   financ.emissao = moment(financ.emissao).toDate()
        //   financ.vencimento = moment(financ.vencimento).toDate()
        //   financ.created_at = moment(financ.created_at).toDate()
        // });


        if (data === 'nao_permitido') {
          this.$router.replace({ path: '/acessorestrito' })
        }else{
          this.loadingData = false
          this.selectedAutoValueCidade = this.clienteData.cidade

          this.$router.replace({ path: '/clientedetalhe/' + this.clienteData.id })
        }
      })

      this.getOcorrencias(idcliente)
      this.getReferencias(idcliente)

    }else{

      // this.clienteData.nome = 'Cliente testes 001'
      // this.clienteData.fantasia = 'Cliente testes 001'
      // this.clienteData.cnpj = '20404325000147'
      // this.clienteData.inscricao = 'ISENTO'
      // this.clienteData.nascim = '2022-01-01'
      // this.clienteData.tipo = 'J'
      // this.clienteData.tel1 = '27999999999'
      // this.clienteData.email = 'testes@gmail.com'
      // this.clienteData.cep = '35230000'
      // this.clienteData.rua = 'AV TESTES'
      // this.clienteData.numero = '0008'
      // this.clienteData.bairro = 'CENTRO'
      // this.clienteData.cidade = 'VITORIA'
      // this.clienteData.compl = 'complento'
      // this.clienteData.estado = 'ES'
      // this.clienteData.repres = '000107'
      // this.clienteData.limite = 1000
      // this.clienteData.observacao = 'teste de observação'
      //
      // this.getReferencias(32)

      this.clienteService.getClienteNovo().then((dataClienteNovo) => {
        console.log('getClienteNovo')
        console.log(dataClienteNovo)

        this.userData = dataClienteNovo[0]
        //let representante = dataPedNovo[1]

        // this.pedidoData['repres'] = representante.codexterno
        // this.selectedAutoValueRepres = representante.descricao


        if (this.userData.tipo === 'repre'){
          console.log('perfil_repres')
          this.clienteData.repres = this.userData.codrepres
        }

        this.loadingData = false

      })


    }

  },

  methods: {

      alterouTab(event){
          console.log('entrouTabFinanc')
          console.log(event.index)
          if (event.index === 1) {

              this.clienteService.getssclifinancs(
                  this.clienteData.codexterno
              ).then((data) => {
                  console.log('this.financsData')
                  if (data){
                      console.log(data)
                  }else{
                      console.log('nao data')
                  }


                  this.financeirosData = data.financeiro
                  this.totalAberto = data.totalberto
                  this.totalVencido = data.totalVencto
                  this.loadingFinanceiro = false
                  //


                  let totalAberto = 0
                  let totalVencto = 0
                  this.financeirosData.forEach(financ => {
                      financ.emissao = moment(financ.emissao).toDate()
                      financ.vencimento = moment(financ.vencimento).toDate()
                      financ.created_at = moment(financ.created_at).toDate()

                      financ.stsvenc = ''
                      if (financ.status !== 'B'){
                          totalAberto += financ.valor
                          if (financ.vencimento <= moment.now()){
                              totalVencto += financ.valor
                              financ.stsvenc = 'S'

                          }
                      }




                  })

                  this.vlrTotalFinancAberto = totalAberto
                  this.vlrTotalFinancVencido = totalVencto


              })
          } else if (event.index === 2) {

              this.clienteService.getsscliprodaberto(
                  this.clienteData.codexterno
              ).then((data) => {
                  console.log('this.getsscliprodaberto')
                  if (data){
                      console.log(data)
                  }else{
                      console.log('nao data')
                  }

                  this.prodAbertoData = data
                  this.loadingProdAberto = false

                  //
                  //

                   this.prodAbertoData.forEach(produto => {
                       produto.emiss = moment(produto.emiss).toDate()
                   })
                  //     financ.emissao = moment(financ.emissao).toDate()
                  //     financ.vencimento = moment(financ.vencimento).toDate()
                  //     financ.created_at = moment(financ.created_at).toDate()
                  //
                  //     financ.stsvenc = ''
                  //     if (financ.status !== 'B'){
                  //         totalAberto += financ.valor
                  //         if (financ.vencimento <= moment.now()){
                  //             totalVencto += financ.valor
                  //             financ.stsvenc = 'S'
                  //
                  //         }
                  //
                  //
                  //
                  //
                  // })
                  //
                  // this.vlrTotalFinancAberto = totalAberto
                  // this.vlrTotalFinancVencido = totalVencto


              })
          }
      },

    confCliente(){
      console.log('confCliente_0')

      console.log(this.refersData.length)
      console.log(this.refersData)

      // REGRA CONTAGEM REFERENCIAS
      // if (!this.refersData || this.refersData.length < 3){
      //  this.activeIndex = 4
      //  this.$toast.add({severity:'warn', summary: 'Validação', detail:'Obrigatório mínimo de 3 referências comerciais do cliente, favor corrigir', life: 3000});

      // }else{


        this.validConfirmar = true

        if (this.clienteData.nome && this.clienteData.tipo && this.clienteData.fantasia && this.clienteData.rua && this.clienteData.bairro && this.clienteData.tel1
            && this.clienteData.email && this.clienteData.repres && this.clienteData.limite && this.clienteData.estado && this.clienteData.cidade && this.clienteData.cnpj
            && this.clienteData.inscricao && this.clienteData.cep
        ){
          console.log('confCliente_1')



          this.$confirm.require({
            group: 'dialog',
            header: 'Confirmação',
            message: 'Após confirmação o cliente não poderá mais ser alterado, tem certeza que deseja confirmar?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
              this.loadingData = true

              this.clienteService.confirmarCli({
                'cliente': this.clienteData,
                'refers': this.refersData
              }).then((dataCli) => {
                console.log('confirmarCli')
                var retProtheus = JSON.parse(dataCli[1])
                this.loadingBtnSave = false

                if (this.clienteData.id === 0){
                  this.clienteData.id = dataCli[0].id
                  this.$router.replace({ path: '/clientedetalhe/' + this.clienteData.id }).then(() => {
                    if (retProtheus.errorCode && retProtheus.errorCode === 400){
                      this.$toast.add({
                        severity: 'warn',
                        summary: 'Problemas com WS Protheus',
                        detail: retProtheus.errorMessage,
                        life: 5000
                      });
                    }else{
                      this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
                      this.clienteData.codexterno = dataCli[0].codexterno
                      this.clienteData.status = dataCli[0].status
                    }
                  })
                }else{
                  if (retProtheus.errorCode && retProtheus.errorCode === 400){
                    this.$toast.add({
                      severity: 'warn',
                      summary: 'Problemas com WS Protheus',
                      detail: retProtheus.errorMessage,
                      life: 5000
                    });
                  }else{
                    this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
                    this.clienteData.codexterno = dataCli[0].codexterno
                    this.clienteData.status = dataCli[0].status
                  }
                }
                this.loadingData = false

              })
            },
            reject: () => {
            }
          })



        }else{
          this.activeIndex = 0
        }


    },

    nomeTipo(sigla){
      switch (sigla) {
        case '1':
          return 'Instituição'
        case '2':
          return 'Empresa'
        default:
          return 'Não Definido'
      }
    },

    nomeStatusFinan(sigla){
      switch (sigla) {
        case 'A':
          return 'Em Aberto'
        case 'B':
          return 'Baixado'
        default:
          return 'Não Definido'
      }
    },

    nomeStatus(sigla){
      switch (sigla) {
        case 'A':
          return 'Ativo'
        case 'I':
          return 'Inativo'
        case 'EL':
          return 'Elaboração'
        case 'CO':
          return 'Confirmado'
        default:
          return 'Não Definido'
      }
    },

    beforeUpload(){

    },

    getOcorrencias(idcliente){
      this.clienteService.getsscliocors(idcliente).then((data) => {
        console.log('this.ocorsData')
        this.ocorsData = data
        this.loadingOcors = false
        console.log(this.ocorsData)
        this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
      });
    },

    getReferencias(idcliente){
      this.clienteService.getssclirefers(idcliente).then((data) => {
        // console.log('this.refersData')
        this.refersData = data
        this.loadingRefers = false

        this.refersData.forEach(referencia => {
          referencia.created_at = moment(referencia.created_at).toDate()
        });

        // console.log(this.refersData)
      });
    },

    excluirOcor(){
      console.log('excluirOcor')
      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.clienteService.ssdeleteocor(this.ocorrenciaData.id).then(() => {
            this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
            this.sidebarOcorrencia = false
            this.getOcorrencias(this.clienteData.id)
          })
        },
        reject: () => {

        }
      });
    },

    excluirRefer(){
      console.log('excluirRefer')
      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.clienteService.ssdeleterefer(this.referenciaData.id).then(() => {
            this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
            this.sidebarReferencia = false
            this.getReferencias(this.clienteData.id)
          })
        },
        reject: () => {

        }
      });
    },



    abrirOcor(idOcor){
      console.log('abrirOcor')
      console.log(idOcor)
      this.clienteService.getsscliocor(idOcor).then((data) => {
        console.log('this.ocorData')
        //console.log(data[0])
        this.ocorrenciaData = data[0]
        this.sidebarOcorrencia = true
      });
    },

    abrirRefer(idRefer){
      console.log('abrirRefer')
      console.log(idRefer)
      this.clienteService.getssclirefer(idRefer).then((data) => {
        console.log('this.referData')
        //console.log(data[0])
        this.referenciaData = data[0]
        this.sidebarReferencia = true
      });
    },

    saveOcorrencia() {
      console.log('sssaveocor')
      // console.log(this.ocorrenciaData)
      this.validOcor = true
      this.loadingBtnSaveOcor = true
      if (this.ocorrenciaData.tipo && this.ocorrenciaData.obs){
        this.clienteService.savessocor(this.ocorrenciaData).then(() => {
          // console.log('savessocor')
          // console.log(dataOcor)
          this.loadingBtnSaveOcor = false
          this.sidebarOcorrencia = false
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});


          this.getOcorrencias(this.clienteData.id)
          // this.clienteService.getsscliocors(this.clienteData.id).then((data) => {
          //   console.log('this.ocorsData')
          //   this.ocorsData = data
          //   this.loadingOcors = false
          //   console.log(this.ocorsData)
          //   this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
          // });

        });
      }else{
        this.loadingBtnSaveOcor = false
      }
    },

    saveReferencia() {
      console.log('saveReferencia')
      // console.log(this.ocorrenciaData)
      this.validRefer = true
      this.loadingBtnSaveRefer = true
      if (this.referenciaData.tipo && this.referenciaData.nome && this.referenciaData.tel1 && this.referenciaData.contato){
        this.clienteService.savessrefer(this.referenciaData).then(() => {
          // console.log('savessocor')
          // console.log(dataOcor)
          this.loadingBtnSaveRefer = false
          this.sidebarReferencia = false
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          this.getReferencias(this.clienteData.id)
        });
      }else{
        this.loadingBtnSaveRefer = false
      }
    },

    novaOcor(){
      console.log('novaOcor')
      this.ocorrenciaData = {
        'pessoa_id': this.clienteData.id
      }
      this.validOcor = false
      this.sidebarOcorrencia = true
      this.activeIndex = 3

    },

    novaRefer(){
      console.log('novaRefer')
      this.referenciaData = {
        'pessoa_id': this.clienteData.id,
        'tipo': '2'
      }
      this.validRefer = false
      this.sidebarReferencia = true
      this.activeIndex = 4

    },

    excluirFile(arquivo){
      console.log('excluirFile')
      console.log(arquivo.id)

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o arquivo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          console.log('confirmou')

          this.clienteService.ssdeletefile(arquivo.id).then((data) => {
            console.log('data delete file')
            console.log(data)


            if (arquivo.tipo === 'conta_energia'){
              this.arqContaEnergia = null
            } else if (arquivo.tipo === 'relogio'){
              this.arqRelogio = null
            } else if (arquivo.tipo === 'quadro_distribuicao'){
              this.arqQuadroDistribuicao = null
            } else if (arquivo.tipo === 'disjuntor_geral'){
              this.arqDisjuntorGeral = null
            } else if (arquivo.tipo === 'telhado'){
              this.arqTelhado = null
            } else if (arquivo.tipo === 'faixada'){
              this.arqFaixada = null
            }


            this.$toast.add({severity:'success', summary: 'Arquivo excluído', detail:'As informações foram excluídas com sucesso', life: 3000});

          })


        },
        reject: () => {

        }
      });
    },


    downloadFile(arquivo){
      console.log('downloadFile')
      console.log(arquivo)

      window.open(api.getUri() + 'uploads/'+ arquivo, '_blank');
    },

    onUpload() {
      console.log('onUpload')
      this.atualizaListaImages()
      this.$toast.add({severity:'success', summary: 'Arquivo gravado', detail:'As informações foram atualizadas com sucesso', life: 3000});

    },

    selecionaCidade(event){
      console.log('selecionaCliente')
      // console.log(event.value)
      this.clienteData['cidade'] = event.value.descricao
      this.clienteData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.clienteService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.descricao.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },

    pesquisarCEP() {
      if (!this.clienteData.cep) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de cep inválido, favor corrigir', life: 3000});
      }else{
        this.loadingCEP = true
        let cepFormated = this.clienteData.cep.replace('.','').replace('-', '');
        this.clienteService.getPesquisaCEP(cepFormated).then((dataCEP) => {
          console.log('getPesquisaCEP')
          console.log(dataCEP)

          if (dataCEP.cep){
            this.clienteData.bairro = dataCEP.bairro
            this.clienteData.compl = dataCEP.complemento
            this.clienteData.cidade = dataCEP.localidade
            this.selectedAutoValueCidade = dataCEP.localidade
            this.clienteData.rua = dataCEP.logradouro
            this.clienteData.estado = dataCEP.uf
          }else{
            this.$toast.add({severity:'warn', summary: 'Validação', detail:'Retorno de consulta inválido, favor preencher manualmente os dados', life: 3000});
          }
          this.loadingCEP = false
        });
      }


    },

    enviarEmail(){
      if (!this.clienteData.email) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de e-mail inválido, favor corrigir', life: 3000});
      }else{
        window.open("mailto:"+this.clienteData.email);
      }
    },

    enviarZap(){
      if (!this.clienteData.telefone) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho do número de telefone inválido, favor corrigir', life: 3000});
      }else{
        window.open('https://api.whatsapp.com/send?phone=5527999722245', '_blank');
      }
    },

    loadBtn(index) {
      this.loadingBtn[index] = true;
      setTimeout(() => this.loadingBtn[index] = false, 1000);
    },

    saveCliente() {
      this.validCliente = true
      if (this.clienteData.nome && this.clienteData.cnpj){
        this.loadingBtnSave = true

        this.clienteService.savesscliente({
          'cliente': this.clienteData
        }).then((dataCli) => {
          console.log('savesscliente')
          this.loadingBtnSave = false
          if (this.clienteData.id === 0){
            this.clienteData.id = dataCli[0].id
            this.$router.replace({ path: '/clientedetalhe/' + this.clienteData.id }).then(() => {
              this.$toast.add({
                severity: 'success',
                summary: 'Registro atualizado',
                detail: 'As informações foram atualizadas com sucesso',
                life: 3000
              });
            })
          }else {
            this.$toast.add({
              severity: 'success',
              summary: 'Registro atualizado',
              detail: 'As informações foram atualizadas com sucesso',
              life: 3000
            });
          }
        })
      }
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
